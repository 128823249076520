import qs from 'qs';
import React, { useState } from 'react';
import styled from 'styled-components';
import { EventHeaderMobile } from 'components/Events';
import { Select } from 'components/Form/Select';
import Spacer from 'components/Spacer';
import { useGetRefundStatus } from 'components/Transactions';
import MobileSearchInput from 'pages/employee/Events/search';
import { useGetRegistrationTickets } from './gql/useGetRegistrationTickets';
import { RegistrationsTransactionsListMobile as List } from './RegistrationsTransactionsListMobile';

const FilterContainer = styled.div`
  min-width: 230px;
  margin-left: 25px;

  @media screen and (max-width: 860px) {
    margin-left: 0;
    margin-bottom: 10px;
    padding: 0 20px;
  }
`;

export const RegistrationsMobile = (props) => {
  const { match, location } = props;
  const [search, setSearch] = useState('');
  const { data: tickets, loading } = useGetRegistrationTickets();
  const [ticketFilter, setTicketFilter] = useState(null);

  const { data } = useGetRefundStatus();

  const { page } = qs.parse(window.location.search.substring(1));

  const handleChange = (e) => setSearch(e.target.value);

  const handleBlur = (e) => {
    setSearch(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      setSearch(e.target.value);
    }
  };

  const urlParams = new URLSearchParams(window.location.search);
  const currentPage = parseInt(urlParams.get('page'));

  return loading ? (
    <div></div>
  ) : (
    <div>
      <MobileSearchInput
        placeholder="Search Registrations.."
        onChange={handleChange}
        value={search}
        handleChange={handleChange}
        handleBlur={handleBlur}
        handleKeyPress={handleKeyPress}
      />

      <Spacer size={10} />
      <EventHeaderMobile noDropdown queryDate={'2020-05-23'} noMargin />

      <Spacer size={10} />
      <FilterContainer>
        <Select
          placeholder="All Registrations"
          options={tickets.getRegistrationTickets.map((ticket) => ({
            label: ticket.name,
            value: ticket.id,
          }))}
          value={
            ticketFilter
              ? ticketFilter
              : { label: 'All Registrations', value: null }
          }
          isSearchable
          isClearable
          onChange={({ target }) => {
            setTicketFilter(target.value ?? null);
          }}
        />
      </FilterContainer>

      <Spacer size={10} />
      <List
        page={page}
        refundEventStatus={data?.getEventRefundStatus}
        currentPage={currentPage}
        match={match}
        location={{ ...location, search: `?queryString=${search}` }}
        queryString={search}
        ticket_id={ticketFilter ? ticketFilter : null}
      />
    </div>
  );
};
