import React from 'react';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { UpcomingEvents, useGetUpcomingEvent } from 'components/UpcomingEvents';
import { VideoResourcesCarousel as VideoResources } from 'components/Video';
import { Radar, WeatherWidget } from 'components/Weather';
import { useMe } from 'hooks/useMe';
import { useOfflineCheck } from 'hooks/useOfflineCheck';
import useWindowSize from 'hooks/useWindowSize';
import storage from 'shared/storage';
import { useInterval } from 'shared/timeout';

export const EmployeeHome = () => {
  const { data } = useGetUpcomingEvent();
  const { data: meData } = useMe();

  const me = meData && meData.me;
  const events =
    data && data.getUpcomingEvent && Array.isArray(data.getUpcomingEvent)
      ? data.getUpcomingEvent
      : undefined;

  const [width] = useWindowSize();

  const isOffline = useOfflineCheck();

  useInterval(
    () => {
      storage.removeItem('apollo-cache-persist');
    },
    !isOffline ? 3000 : null
  );

  const getLat = () => {
    const lat =
      events && events.length > 0
        ? events[0].track.lat
          ? events[0].track.lat
          : null
        : me && me.track
        ? me.track.lat
        : null;

    return lat;
  };

  const getLng = () => {
    const lng =
      events && events.length > 0
        ? events[0].track.lng
          ? events[0].track.lng
          : null
        : me.track
        ? me.track.lng
        : null;

    return lng;
  };

  return (
    <div style={{ padding: 20, boxSizing: 'border-box' }}>
      <UpcomingEvents userType="Employee" />
      <WeatherWidget Width="377px" />
      {(events && events.length > 0) || (me && me.track) ? (
        <Radar radarwidth={width} width="377px" lat={getLat()} lng={getLng()} />
      ) : null}
      <VideoResources userType="employee" />
    </div>
  );
};
