import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';

import storage from 'shared/storage';

import AuthLayout from '../../layouts/AuthLayout';
import ApolloMainAdmin from '../../apolloMainAdmin';

const AuthRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        const user = storage.get('user', {});

        if (typeof user.role !== 'undefined') {
          if (user.role === 'admin')
            return (
              <Redirect
                to={{
                  pathname: '/admin/series',
                  state: { from: props.location }
                }}
              />
            );
          else if (user.role === 'track' && rest.path !== '/stripe')
            return (
              <Redirect
                to={{
                  pathname: '/admin-track/home',
                  state: { from: props.location }
                }}
              />
            );
          else if (
            user.role === 'employee' &&
            rest.path !== '/admin-employee/set-password'
          )
            return (
              <Redirect
                to={{
                  pathname: '/admin-employee/home',
                  state: { from: props.location }
                }}
              />
            );
        }

        return (
          <ApolloMainAdmin>
            <AuthLayout>
              <Component {...props} />
            </AuthLayout>
          </ApolloMainAdmin>
        );
      }}
    />
  );
};

export default withRouter(AuthRoute);
