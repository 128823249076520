import { Formik } from 'formik';
import { omit } from 'lodash';
import moment from 'moment';
import React from 'react';
import { Row, Col } from 'react-grid-system';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Button } from 'components/Button';
import { Drawer, DrawerPadding } from 'components/Drawer';
import {
  useCreateEvent,
  useGetEvent,
  useUpdateEvent,
} from 'components/Events/gql';
import { FaqList } from 'components/Faqs';
import { useGetMatrices } from 'components/FeeMatrices/gql';
import { AutoSuggest } from 'components/Form/AutoSuggest';
import { Checkbox } from 'components/Form/Checkbox';
import { DatePicker } from 'components/Form/DatePicker';
import { Dropzone } from 'components/Form/Dropzone';
import { Input } from 'components/Form/Input';
import { Select } from 'components/Form/Select';
import { Label, ErrorText } from 'components/Form/styles';
import RowSection from 'components/RowSection';
import { SeriesSelect } from 'components/SeriesSelect';
import Spacer from 'components/Spacer';
import AddedBy from './addedBy';
import DateTimeRepeater from './DateTimeRepeater';
import Ownership from './Dropdown/ownership';
import { EditEventTickets as Tickets } from './EditEventTickets';
import { FeesRepeater } from './FeesRepeater';
import Tracks from './tracks';
import { WaiversRepeater } from './WaiversRepeater';

const SectionTitle = styled.p`
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 40px;
`;

const formValues = [
  'name',
  'start_date',
  'added_by',
  'end_date',
  'sale_start',
  'sale_end',
  'off_sale_time',
  'status',
  'admin_tickets',
  'admin_multiday_tickets',
  'admin_other_tickets',
  'admin_registrations',
  'image_id',
  'logo_id',
  'track_id',
  'series_ids',
  'user_id',
  'faqs',
  'admin_website_url',
  'admin_schedule_url',
  'facebook',
  'instagram',
  'twitter',
  'waivers',
  'about',
  'admin_times',
  'hide_fee',
  'international_fee',
  'registration_sale_start',
  'registration_sale_end',
  'registration_off_time',
  'registration_email',
  'other_fees',
  'registration_fees',
  'matrix_id',
  'marketing_fee',
];

export const DESCRIPTION_CHARACTER_LIMIT = 200;

const descriptionError = `Ticket Descriptions must be less than ${DESCRIPTION_CHARACTER_LIMIT} characters long`;

function sortByOrderField(a, b) {
  // We want faqs with no `order` to be last, not first
  return (a.order ?? Infinity) - (b.order ?? Infinity);
}

function getInitialValues(event, keys) {
  const {
    user,
    admin_times,
    admin_tickets,
    admin_other_tickets,
    admin_registrations,
    admin_multiday_tickets,
    faqs,
    off_sale_time,
    registration_off_time,
    series,
    waivers,
    other_fees,
    registration_fees,
    matrix,
  } = event ?? {};
  const blankValues = {
    ...keys.reduce((values, key) => ({ ...values, [key]: '' }), {}),
    user_id: {
      value: '',
      label: '',
    },
    admin_times: [],
    admin_tickets: [],
    admin_other_tickets: [],
    admin_registrations: [],
    faqs: [],
    off_sale_time: null,
    registration_off_time: null,
    series_ids: [],
    waivers: [],
    other_fees: [],
    registration_fees: [],
    marketing_fee: true,
  };
  const initialValues = !event
    ? blankValues
    : {
        ...keys.reduce((formValues, key) => {
          return event[key]
            ? {
                ...formValues,
                [key]: event[key],
              }
            : formValues;
        }, {}),
        user_id: user
          ? {
              value: user.id,
              label: `${user.first_name} ${
                user.middle_name ? `${user.middle_name} ` : ''
              }${user.last_name}`,
            }
          : '',
        ...(Array.isArray(admin_times)
          ? {
              admin_times: admin_times.map((time) => ({
                id: time.id,
                start_time: time.start_time ? setTime(time.start_time) : null,
                gate_time: time.gate_time ? setTime(time.gate_time) : null,
                event_date: time.event_date,
              })),
            }
          : undefined),
        ...(Array.isArray(admin_tickets)
          ? {
              admin_tickets: admin_tickets
                .sort(sortByOrderField)
                .map((ticket) => ({
                  ...ticket,
                  price: ticket.price.toFixed(2),
                })),
            }
          : undefined),
        ...(Array.isArray(admin_multiday_tickets)
          ? {
              admin_multiday_tickets: admin_multiday_tickets
                .sort(sortByOrderField)
                .map((ticket) => ({
                  ...ticket,
                  price: ticket.price.toFixed(2),
                })),
            }
          : undefined),
        admin_other_tickets: admin_other_tickets
          .sort(sortByOrderField)
          .map((ticket) => ({
            id: ticket.id,
            start_date: ticket.start_date,
            end_date: ticket.end_date,
            name: ticket.name,
            price: ticket.price.toFixed(2),
            limit: ticket.limit,
            color_code: ticket.color_code,
            description: ticket.description,
          })),
        admin_registrations: admin_registrations
          .sort(sortByOrderField)
          .map((ticket) => ({
            id: ticket.id,
            start_date: ticket.start_date,
            end_date: ticket.end_date,
            name: ticket.name,
            price: ticket.price.toFixed(2),
            limit: ticket.limit,
            color_code: ticket.color_code,
            form_id: ticket.form_id,
          })),
        ...(faqs
          ? {
              faqs: faqs
                .sort(sortByOrderField)
                .map((faq) => omit(faq, ['__typename'])),
            }
          : []),
        ...(off_sale_time ? { off_sale_time: setTime(off_sale_time) } : null),
        ...(registration_off_time
          ? { registration_off_time: setTime(registration_off_time) }
          : null),
        series_ids: Array.isArray(series)
          ? series.map((series) => ({
              value: series.id,
              label: series.name,
            }))
          : [],
        ...(Array.isArray(waivers)
          ? {
              waivers: waivers
                .filter(Boolean)
                .reduce(
                  (allWaivers, waiver) => [
                    ...allWaivers,
                    { waiver: waiver.id },
                  ],
                  []
                ),
            }
          : []),
        other_fees: other_fees
          ? other_fees.map((fee) => {
              // eslint-disable-next-line no-unused-vars
              const { __typename, ...rest } = fee;
              return rest;
            })
          : [],
        registration_fees: registration_fees
          ? registration_fees.map((fee) => {
              // eslint-disable-next-line no-unused-vars
              const { __typename, ...rest } = fee;
              return rest;
            })
          : [],
        matrix_id: matrix
          ? {
              value: matrix.id,
              label: matrix.name,
            }
          : null,
      };
  return initialValues;
}

function getTicketValues(ticket, index) {
  const {
    id,
    name,
    start_date,
    end_date,
    color_code,
    limit,
    price,
    description,
  } = ticket;
  return {
    id,
    name,
    start_date,
    end_date,
    color_code,
    description,
    limit: limit ? parseInt(limit) : null,
    price: price ? parseFloat(price) : 0,
    order: index,
  };
}

function getFeesInput(fees, action) {
  return fees
    .filter(
      (fee) =>
        !Object.entries(fee).reduce((allBlank, [key, value]) =>
          key === 'id' ? allBlank : value ? false : allBlank
        ),
      true
    )
    .map((fee, index) => ({
      ...Object.entries(fee).reduce(
        (properties, [key, value]) => ({
          ...properties,
          [key]: Number(value),
        }),
        {}
      ),
      ...(action !== 'update' ? { id: index } : null),
    }));
}

function getEventInput({
  data,
  currentTrack,
  currentEvent,
  currentSeries,
  action,
}) {
  return {
    ...data,
    ...(action === 'update' ? { id: currentEvent } : null),
    off_sale_time: data.off_sale_time ? getTimes(data.off_sale_time) : null,
    registration_off_time: data.registration_off_time
      ? getTimes(data.registration_off_time)
      : null,
    track_id: currentTrack
      ? parseInt(currentTrack, 10)
      : data.track_id
      ? data.track_id.value
      : null,
    admin_times: data.admin_times
      ? data.admin_times.map((time) => {
          const { id, event_date, gate_time, start_time } = time;
          return {
            id,
            event_date,
            gate_time: gate_time ? getTimes(gate_time) : null,
            start_time: start_time ? getTimes(start_time) : null,
          };
        })
      : [],
    admin_tickets: data.admin_tickets
      ? data.admin_tickets.map((ticket, index) => ({
          ...getTicketValues(ticket, index),
        }))
      : [],
    admin_multiday_tickets: data.admin_multiday_tickets
      ? data.admin_multiday_tickets.map((ticket, index) => ({
          ...getTicketValues(ticket, index),
        }))
      : [],
    admin_registrations: data.admin_registrations
      ? data.admin_registrations.map((ticket, index) => ({
          ...getTicketValues(ticket, index),
          form_id: ticket.form_id ? parseFloat(ticket.form_id) : null,
        }))
      : [],
    admin_other_tickets: data.admin_other_tickets
      ? data.admin_other_tickets.map((ticket, index) => ({
          ...getTicketValues(ticket, index),
          limit: ticket.limit ? parseInt(ticket.limit) : null,
          price: ticket.price ? parseFloat(ticket.price) : 0,
        }))
      : [],
    user_id: data.user_id ? data.user_id.value : null,
    waivers: data.waivers
      ? data.waivers
          .filter(Boolean)
          .map((waiver) => parseInt(waiver.waiver, 10))
      : [],
    series_ids: data.series_ids
      ? data.series_ids.map((item) => item.value)
      : parseInt(currentSeries, 10),
    faqs: data.faqs.map((faq, index) => {
      // Destructure out the `react-sortablejs` additions
      /* eslint-disable-next-line no-unused-vars */
      const { chosen, selected, filtered, ...rest } = faq;
      return {
        ...rest,
        order: index,
      };
    }),
    ...(data.other_fees
      ? {
          other_fees: getFeesInput(data.other_fees, action),
        }
      : null),
    ...(data.registration_fees
      ? {
          registration_fees: getFeesInput(data.registration_fees, action),
        }
      : null),
    ...(data.matrix_id?.value
      ? { matrix_id: data.matrix_id.value }
      : undefined),
  };
}

function validateTicketDescriptionCharacterCounts(tickets, characterLimit) {
  if (!tickets.length) {
    return true;
  }

  const areAllValid = tickets.reduce((allValid, ticket) => {
    return typeof ticket.description === 'string' &&
      ticket.description.length > characterLimit
      ? false
      : allValid;
  }, true);
  return areAllValid;
}

const EventEdit = ({
  isVisible,
  handleOutClick,
  currentEvent,
  currentTrack,
  currentSeries,
  id,
  newEvent,
}) => {
  const successMessage = () =>
    toast.success(currentEvent ? 'Event Updated' : 'Event Created');
  const errorMessage = (response) =>
    toast.error(
      currentEvent
        ? response.global
          ? 'Error Updating Event'
          : "There were errors with your submission check the form's field for errors."
        : 'Error Creating Event'
    );

  const createEvent = useCreateEvent();
  const updateEvent = useUpdateEvent();
  const { data: eventData } = useGetEvent(currentEvent);

  const { data: matrixData } = useGetMatrices({
    variables: { input: { limit: '1000' } },
  });

  const matrices = matrixData?.getMatrices?.results ?? [];

  if (!eventData?.getEvent && !newEvent) return null;

  return (
    <Drawer
      title={currentEvent ? 'Edit Event Details' : 'New Event Details'}
      isVisible={isVisible}
      handleOutClick={handleOutClick}
    >
      <Formik
        enableReinitialize
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={getInitialValues(eventData?.getEvent, formValues)}
        validate={(values) => {
          const errors = {};

          if (!values.name) {
            errors.name = 'Required';
          }

          if (!values.start_date) {
            errors.start_date = 'Required';
          }

          if (!values.end_date) {
            errors.end_date = 'Required';
          }

          if (currentSeries && !values.track_id) {
            errors.track_id = 'Required';
          }

          const ticketsWithDescriptions = [
            'admin_tickets',
            'admin_multiday_tickets',
            'admin_other_tickets',
          ];

          ticketsWithDescriptions.forEach((ticketType) => {
            if (
              !validateTicketDescriptionCharacterCounts(
                values[ticketType],
                DESCRIPTION_CHARACTER_LIMIT
              )
            ) {
              errors[ticketType] = descriptionError;
            }
          });

          const faqErrors = values.faqs.reduce((errors, faq, index) => {
            const { answer, question } = faq;
            const error =
              !answer || !question
                ? {
                    ...(!answer ? { answer: 'Required' } : null),
                    ...(!question ? { question: 'Required' } : null),
                  }
                : undefined;
            return {
              ...errors,
              ...(error ? { [index]: error } : null),
            };
          }, {});
          if (Object.keys(faqErrors).length > 0) errors.faqs = faqErrors;

          function getFeesErrors(fees) {
            return fees.reduce((errors, fee, index) => {
              const { fee_price, fee_amount, minimum, maximum } = fee;
              const error =
                !fee_price ||
                !fee_amount ||
                (!minimum && 0 !== minimum) ||
                !maximum
                  ? {
                      ...(!fee_price ? { fee_price: 'Required' } : null),
                      ...(!fee_amount ? { fee_amount: 'Required' } : null),
                      ...(!minimum ? { minimum: 'Required' } : null),
                      ...(!maximum ? { maximum: 'Required' } : null),
                    }
                  : undefined;
              return {
                ...errors,
                // If all fields are blank, we won't throw an error
                ...(error && Object.keys(error).length < 4
                  ? { [index]: error }
                  : null),
              };
            }, {});
          }

          const otherFeesErrors = getFeesErrors(values.other_fees);
          if (Object.keys(otherFeesErrors).length > 0)
            errors.other_fees = otherFeesErrors;

          const registrationFeesErrors = getFeesErrors(
            values.registration_fees
          );
          if (Object.keys(registrationFeesErrors).length > 0)
            errors.registration_fees = registrationFeesErrors;

          if (Object.keys(errors).length) {
            toast.error('Please check your form for errors..');
          }

          return errors;
        }}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          let response;
          setSubmitting(true);

          const data = Object.entries(values).reduce(
            (acc, [key, value]) => ({
              ...acc,
              [key]: value === '' ? null : value,
            }),
            {}
          );

          if (currentEvent) {
            response = await updateEvent(
              getEventInput({
                data,
                currentTrack,
                currentEvent,
                currentSeries,
                action: 'update',
              })
            );
          } else {
            response = await createEvent(
              getEventInput({
                data,
                currentTrack,
                currentEvent,
                currentSeries,
                action: 'create',
              }),
              //this is for the mutation to know how to update the store
              currentTrack ? 'track' : currentSeries ? 'series' : '',
              currentTrack ? currentTrack : currentSeries
            );
          }

          if (!response || response.errors) {
            errorMessage(response);
            setSubmitting(false);
            return setErrors(response.errors);
          } else {
            successMessage();
            setSubmitting(false);
            handleOutClick();
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setFieldError,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DrawerPadding border>
                <Dropzone
                  id="logo_id"
                  files={
                    values.logo_id
                      ? [{ name: values.logo_id, preview: values.logo }]
                      : []
                  }
                  onChange={setFieldValue}
                  setError={setFieldError}
                  error={errors.logo_id && touched.logo_id && errors.logo_id}
                  label="Event Logo"
                />
                <Dropzone
                  id="image_id"
                  files={
                    values.image_id
                      ? [{ name: values.image_id, preview: values.image }]
                      : []
                  }
                  onChange={setFieldValue}
                  setError={setFieldError}
                  error={errors.image_id && touched.image_id && errors.image_id}
                  label="Event Image"
                />
              </DrawerPadding>

              <DrawerPadding border>
                <Input
                  id="name"
                  name="name"
                  label="Event Name"
                  placeholder="Event Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  error={errors.name}
                />
                <Spacer size={18} />

                {currentSeries ? (
                  <Tracks
                    values={values}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldValue}
                  />
                ) : currentTrack ? (
                  <SeriesSelect isMulti />
                ) : (
                  <>
                    <Tracks
                      values={values}
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldValue}
                    />{' '}
                    <Spacer size={18} />
                    <SeriesSelect isMulti />
                  </>
                )}
              </DrawerPadding>
              <DrawerPadding border>
                <Row>
                  <Col>
                    <DatePicker
                      id="start_date"
                      name="start_date"
                      label="Event Start Date"
                      type="date"
                      test
                      onChange={setFieldValue}
                      value={values.start_date}
                      error={errors.start_date}
                    />
                  </Col>
                  <Col>
                    <DatePicker
                      id="end_date"
                      name="end_date"
                      label="Event End Date"
                      type="date"
                      onChange={setFieldValue}
                      value={values.end_date}
                      error={errors.end_date}
                    />
                  </Col>
                </Row>
                <Spacer size={18} />
                <DateTimeRepeater
                  error={errors.admin_times}
                  dates={values.admin_times || []}
                  onChange={(value) => {
                    handleChange({
                      target: { name: 'admin_times', value },
                    });
                  }}
                />
              </DrawerPadding>
              <DrawerPadding border>
                <SectionTitle>Event Sale Dates/Times</SectionTitle>
                <Row>
                  <Col>
                    <DatePicker
                      id="sale_start"
                      name="sale_start"
                      label="Ticket Sales Start"
                      type="date"
                      test
                      onChange={setFieldValue}
                      value={values.sale_start}
                      error={
                        errors.sale_start &&
                        touched.sale_start &&
                        errors.sale_start
                      }
                    />
                  </Col>
                  <Col>
                    <DatePicker
                      id="sale_end"
                      name="sale_end"
                      label="Ticket Sales End"
                      type="date"
                      onChange={setFieldValue}
                      value={values.sale_end}
                      error={
                        errors.sale_end && touched.sale_end && errors.sale_end
                      }
                    />
                  </Col>
                </Row>
                <Spacer size={18} />
                <Row>
                  <Col>
                    <DatePicker
                      id={'off_sale_time'}
                      type="time"
                      label={`Ticket Off Sale Time`}
                      name="off_sale_time"
                      value={values.off_sale_time}
                      onChange={(name, event) => {
                        handleChange({
                          target: { name, value: event.target.value },
                        });
                      }}
                    />
                  </Col>
                  <Col></Col>
                </Row>
                <Spacer size={18} />
                <Row>
                  <Col>
                    <DatePicker
                      id="registration_sale_start"
                      name="registration_sale_start"
                      label="Registration Sales Start"
                      type="date"
                      test
                      onChange={setFieldValue}
                      value={values.registration_sale_start}
                      error={
                        errors.registration_sale_start &&
                        touched.registration_sale_start &&
                        errors.registration_sale_start
                      }
                    />
                  </Col>
                  <Col>
                    <DatePicker
                      id="registration_sale_end"
                      name="registration_sale_end"
                      label="Registration Sales End"
                      type="date"
                      onChange={setFieldValue}
                      value={values.registration_sale_end}
                      error={
                        errors.registration_sale_end &&
                        touched.registration_sale_end &&
                        errors.registration_sale_end
                      }
                    />
                  </Col>
                </Row>
                <Spacer size={18} />
                <Row>
                  <Col>
                    <DatePicker
                      id={'registration_off_time'}
                      type="time"
                      label={`Registration Off Sale Time`}
                      name="registration_off_time"
                      value={values.registration_off_time}
                      onChange={(name, event) => {
                        handleChange({
                          target: { name, value: event.target.value },
                        });
                      }}
                    />
                  </Col>
                  <Col></Col>
                </Row>
              </DrawerPadding>

              <Tickets
                tickets={values.admin_tickets || []}
                ticketType="admin_tickets"
                addButtonText="Add Single Day Ticket"
                onChange={(value) => {
                  handleChange({
                    target: { name: 'admin_tickets', value },
                  });
                }}
                error={errors.admin_tickets}
              />

              <Tickets
                tickets={values.admin_multiday_tickets || []}
                ticketType="admin_multiday_tickets"
                addButtonText="Add Multi Day Ticket"
                onChange={(value) => {
                  handleChange({
                    target: { name: 'admin_multiday_tickets', value },
                  });
                }}
                error={errors.admin_multiday_tickets}
              />

              <Tickets
                tickets={values.admin_registrations || []}
                ticketType="admin_registrations"
                addButtonText="Add New Registration"
                onChange={(value) => {
                  handleChange({
                    target: { name: 'admin_registrations', value },
                  });
                }}
                error={errors.admin_registrations}
              />

              <Tickets
                tickets={values.admin_other_tickets || []}
                ticketType="admin_other_tickets"
                addButtonText="Add Another Ticket"
                onChange={(value) => {
                  handleChange({
                    target: { name: 'admin_other_tickets', value },
                  });
                }}
                error={errors.admin_other_tickets}
              />

              <DrawerPadding border>
                <FaqList />
              </DrawerPadding>
              <DrawerPadding border>
                <Input
                  id="admin_website_url"
                  label="Event Website"
                  placeholder="Website URL"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.admin_website_url}
                  error={
                    errors.admin_website_url &&
                    touched.admin_website_url &&
                    errors.admin_website_url
                  }
                />
                <Spacer size={24} />
                <Input
                  id="admin_schedule_url"
                  label="Event Schedule"
                  placeholder="Schedule URL"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.admin_schedule_url}
                  error={
                    errors.admin_schedule_url &&
                    touched.admin_schedule_url &&
                    errors.admin_schedule_url
                  }
                />
                <Spacer size={24} />
                <Input
                  id="about"
                  as="textarea"
                  label="About Event"
                  placeholder="Enter a description about the event"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.about}
                  error={errors.about && touched.about && errors.about}
                />
                <Spacer size={24} />
                <Label>Event Socials</Label>
                <RowSection leftText="Twitter">
                  <Input
                    id="twitter"
                    placeholder="Twitter Handle"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.twitter}
                    error={errors.twitter && touched.twitter && errors.twitter}
                  />
                </RowSection>
                <Spacer size={10} />
                <RowSection leftText="Facebook">
                  <Input
                    id="facebook"
                    placeholder="Facebook Link"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.facebook}
                    error={
                      errors.facebook && touched.facebook && errors.facebook
                    }
                  />
                </RowSection>
                <Spacer size={10} />
                <RowSection leftText="Instagram">
                  <Input
                    id="instagram"
                    placeholder="Instagram Handle"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.instagram}
                    error={
                      errors.instagram && touched.instagram && errors.instagram
                    }
                  />
                </RowSection>
              </DrawerPadding>
              <DrawerPadding border>
                <Label>Financial Settings</Label>
                <Spacer size={10} />
                <AutoSuggest
                  id="matrix_id"
                  label="Fee Matrix"
                  closeMenuOnSelect
                  isClearable
                  options={matrices.map((matrix) => ({
                    value: matrix.id,
                    label: matrix.name,
                  }))}
                  onChange={(value) => setFieldValue('matrix_id', value)}
                  value={values.matrix_id}
                />
                {errors.matrix_id && (
                  <ErrorText fontSize={12}>{errors.matrix_id}</ErrorText>
                )}
                <Spacer size={10} />
                <Checkbox
                  name="international_fee"
                  checked={!!values.international_fee}
                  onChange={(event) => {
                    const value = event.target?.checked;
                    handleChange({
                      target: {
                        name: 'international_fee',
                        value,
                      },
                    });
                  }}
                  onBlur={handleBlur}
                  error={
                    errors.international_fee &&
                    touched.international_fee &&
                    errors.international_fee
                  }
                  rightText="Collection International Fees?"
                />
                <Spacer size={10} />
                <Checkbox
                  name="hide_fee"
                  checked={!!values.hide_fee}
                  onChange={(event) => {
                    const value = event.target?.checked;
                    handleChange({
                      target: {
                        name: 'hide_fee',
                        value,
                      },
                    });
                    if (value) {
                      handleChange({
                        target: {
                          name: 'marketing_fee',
                          value: false,
                        },
                      });
                    }
                  }}
                  onBlur={handleBlur}
                  error={errors.hide_fee && touched.hide_fee && errors.hide_fee}
                  rightText="Hide Fees at Checkout?"
                />
                <Spacer size={18} />
                <Checkbox
                  name="marketing_fee"
                  checked={!!values.marketing_fee}
                  onChange={(event) => {
                    const value = event.target?.checked;
                    handleChange({
                      target: {
                        name: 'marketing_fee',
                        value,
                      },
                    });
                  }}
                  onBlur={handleBlur}
                  error={
                    errors.marketing_fee &&
                    touched.marketing_fee &&
                    errors.marketing_fee
                  }
                  rightText="Collect Marketing Fees?"
                />
                <Spacer size={18} />
                <FeesRepeater
                  feeType="other"
                  error={errors.other_fees}
                  fees={values.other_fees || []}
                  onChange={(value) => {
                    handleChange({
                      target: { name: 'other_fees', value },
                    });
                  }}
                  errors={errors.other_fees}
                />
                <Spacer size={18} />
                <FeesRepeater
                  feeType="registration"
                  error={errors.registration_fees}
                  fees={values.registration_fees || []}
                  onChange={(value) => {
                    handleChange({
                      target: { name: 'registration_fees', value },
                    });
                  }}
                  errors={errors.registration_fees}
                />
              </DrawerPadding>
              <DrawerPadding border>
                <WaiversRepeater
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  errors={errors}
                  values={values}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldValue}
                  currentTrack={currentTrack}
                  currentSeries={currentSeries}
                  currentEvent={currentEvent}
                  id={id ?? currentTrack}
                />
                <Spacer size={18} />
                <Input
                  id="registration_email"
                  name="registration_email"
                  label="Registration Email"
                  placeholder="test@email.com"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.registration_email}
                  error={errors.registration_email}
                />
                <Spacer size={18} />
                <Ownership
                  values={values}
                  errors={errors}
                  touched={touched}
                  id={id ?? currentTrack}
                  setFieldValue={setFieldValue}
                  currentSeries={currentSeries}
                  currentTrack={currentTrack}
                  currentEvent={currentEvent}
                  setFieldTouched={setFieldValue}
                />
                <Spacer size={18} />

                <AddedBy
                  value={values.added_by}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  currentSeries={currentSeries}
                  setFieldTouched={setFieldValue}
                />

                <Spacer size={18} />
                <Select
                  id="status"
                  label="Status"
                  placeholder="Status"
                  options={[
                    { label: 'Draft', value: 'draft' },
                    { label: 'Published', value: 'published' },
                    { label: 'Postponed', value: 'postponed' },
                    { label: 'Cancelled', value: 'cancelled' },
                  ]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.status}
                  error={errors.status && touched.status && errors.status}
                />
              </DrawerPadding>
              <DrawerPadding>
                <Button type="submit" disabled={isSubmitting} block>
                  {currentEvent ? 'Update Event' : 'Add Event'}
                </Button>
              </DrawerPadding>
            </form>
          );
        }}
      </Formik>
    </Drawer>
  );
};

function getTimes(date) {
  if (!date) return;
  return moment(date).format('HH:mm:ss');
}

function setTime(value) {
  if (!value) {
    const defaultTime = new Date();
    defaultTime.setHours(18);
    defaultTime.setMinutes(0);
    return defaultTime;
  }

  if (Object.prototype.toString.call(value) === '[object Date]') {
    return value;
  }

  const pieces = value.split(':');

  const date = new Date();

  date.setHours(parseInt(pieces[0]));
  date.setMinutes(parseInt(pieces[1]));

  return date;
}

export default EventEdit;
