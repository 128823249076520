import React from 'react';
import styled from 'styled-components';
import { UpcomingEvents } from 'components/UpcomingEvents';
import { VideoResourcesCarousel as VideoResources } from 'components/Video';
import { WeatherWidget } from 'components/Weather';
import { Metrics } from '../Metrics';

const FlexContainer = styled.div`
  @media (min-width: 768px) {
    display: flex;
  }
`;

export const TrackHome = () => {
  return (
    <div style={{ boxSizing: 'border-box' }}>
      <FlexContainer>
        <UpcomingEvents userType="track" />
        <WeatherWidget Width="377px" showButton={true} />
        <Metrics />
      </FlexContainer>
      <VideoResources userType="track" />
    </div>
  );
};
