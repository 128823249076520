import PropTypes from 'prop-types';
import React from 'react';
import { useGetUpcomingEvent } from 'components/UpcomingEvents/useGetUpcomingEvent';
import { WeatherWidget, Radar } from 'components/Weather';
import styled from 'styled-components';

const Wrapper = styled.div`
  @media (min-width: 864px) {
    display: flex;
    align-items: flex-start;
  }
`;

export const WeatherPage = props => {
  const { userType = 'admin-track' } = props;
  console.log('file: WeatherPage.js ~ line 15 ~ userType', userType);
  const { data } = useGetUpcomingEvent();

  const width = userType === 'admin-track' ? '800px' : '377px';
  console.log('file: WeatherPage.js ~ line 20 ~ width', width);

  const events = data?.getUpcomingEvent;
  const me = data?.me;

  if (!events && !me) return null;

  function getLatOrLng(which) {
    const lat = events.length
      ? events[0].track[which]
        ? events[0].track[which]
        : null
      : me.track
      ? me.track[which]
      : null;

    return lat;
  }

  return (
    <Wrapper>
      <WeatherWidget Width="377px" showHourly />
      {events.length || me.track ? (
        <Radar
          radarwidth={769}
          Width={userType === 'admin-track' ? '800px' : '377px'}
          lat={getLatOrLng('lat')}
          lng={getLatOrLng('lng')}
        />
      ) : null}
    </Wrapper>
  );
};
WeatherPage.propTypes = {
  userType: PropTypes.oneOf(['admin-track', 'employee'])
};
