import React from 'react';
import styled from 'styled-components';
import MediaQuery from 'react-responsive';

import Images from 'images';

const Logo = styled.img`
  width: 217px;
  height: 82px;
  margin: 0 auto 30px auto;
`;

const BackgroundContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const AuthContainer = styled.div`
  background-color: #fff;
  width: 592px;
  border-radius: 5px;
  margin: 140px auto 0 auto;
  display: flex;
  flex-direction: column;
  padding: 50px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    background-color: transparent;
  }
`;

const LogoSpacer = styled.div`
  text-align: center;
  margin: 60px 0;
`;

const AdminLayout = ({ children, ...rest }) => {
  return (
    <BackgroundContainer>
      <MediaQuery query="(min-width: 768px)">
        <AuthContainer>
          <Logo
            src={
              'pitpay' === process.env.REACT_APP_PLATFORM
                ? Images.logoHorizontalOrange
                : Images.logoKartPassHorizontal
            }
          />
          {children}
        </AuthContainer>
      </MediaQuery>
      <MediaQuery query="(max-width: 768px)">
        {'pitpay' === process.env.REACT_APP_PLATFORM ? (
          <LogoSpacer>
            <Logo src={Images.logoHorizontalWhite} />
          </LogoSpacer>
        ) : (
          <LogoSpacer>
            <Logo src={Images.logoKart} />
          </LogoSpacer>
        )}
        {children}
      </MediaQuery>
    </BackgroundContainer>
  );
};

export default AdminLayout;
