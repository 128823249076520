import { useQuery } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useParams } from 'react-router-dom';
import { RegistrationValuesFragment } from 'components/Registrations/gql';

export const GET_ALL_EVENT_REGISTRATIONS = gql`
  query GetAllEventRegistrations(
    $getTrackTransactionsInput: EventRegistrationsInput!
  ) {
    getAllEventRegistrations(input: $getTrackTransactionsInput) {
      count
      results {
        ...registrationValues
      }
    }
  }
  ${RegistrationValuesFragment}
`;

export function useGetAllEventRegistrations(ticket_id) {
  const { id } = useParams();
  const result = useQuery(GET_ALL_EVENT_REGISTRATIONS, {
    variables: {
      getTrackTransactionsInput: {
        event_id: Number(id),
        ...(ticket_id ? { ticket_id: Number(ticket_id) } : null),
      },
    },
    skip: !id,
  });
  return result;
}
