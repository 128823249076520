import { graphql } from '@apollo/react-hoc';
import gql from 'graphql-tag';
import moment from 'moment';

export const query = gql`
  query GetTicketsByDate($input: GetEventTicketsInput!) {
    getTicketsByDate(input: $input) {
      id
      name
      price
      date
    }
  }
`;
export const secondQuery = gql`
  query GetTicketsByOtherDate($input: GetEventTicketsInput!) {
    getTicketsByDate(input: $input) {
      id
      name
      price
      date
    }
  }
`;
export default graphql(query, {
  options: (props) => {
    return {
      variables: {
        input: {
          event_id: props.selectedEvent.id,
          date: moment(props.selectedEvent.start_date, 'MM-DD-YYYY').format(
            'YYYY-MM-DD'
          )
        }
      },
      fetchPolicy: 'no-cache'
    };
  }
});
