import React from 'react';
import { DatePicker } from 'components/Form/DatePicker';
import Spacer from 'components/Spacer';
import { Button, RemoveButton } from 'components/Button';
import { ErrorText } from 'components/Form/styles';
import Text from 'components/Text';

const DateSection = ({ handleChange, date, index, handleDelete }) => {
  return (
    <div>
      <RemoveButton
        style={{ marginBottom: 20, marginTop: 20 }}
        onClick={handleDelete}
      />
      <div style={{ marginTop: 10 }}>
        <DatePicker
          name={`event_date_${index}`}
          type="date"
          label="Date"
          value={date.event_date}
          onChange={(_, value) =>
            handleChange({ target: { name: 'event_date', value } })
          }
        />
      </div>
      <div style={{ marginTop: 10 }}>
        <DatePicker
          id={`gate_time_${index}`}
          name={`gate_time_${index}`}
          type="time"
          label={`Pit Gate Time`}
          value={date.gate_time}
          onChange={(_, event) => {
            handleChange({
              target: { name: 'gate_time', value: event.target.value }
            });
          }}
        />
      </div>
      <div style={{ marginTop: 10 }}>
        <DatePicker
          id={`start_time_${index}`}
          name={`start_time_${index}`}
          type="time"
          label={`Race Start Time`}
          value={date.start_time}
          onChange={(_, event) => {
            handleChange({
              target: { name: 'start_time', value: event.target.value }
            });
          }}
        />
      </div>

      <Spacer size={18} />
    </div>
  );
};

const DateTimeRepeater = ({ dates, onChange, error }) => {
  return (
    <div>
      <Text type="label" color="#000">
        Gate and Start Times
      </Text>
      {dates.map((date, index) => (
        <DateSection
          date={date}
          index={index}
          key={index}
          handleDelete={() => {
            const newDates = dates.filter(
              (_, currentIndex) => currentIndex !== index
            );
            onChange(newDates);
          }}
          handleChange={({ target }) => {
            const newDates = dates.map((date, currentIndex) =>
              currentIndex === index
                ? { ...date, [target.name]: target.value }
                : date
            );
            onChange(newDates);
          }}
        />
      ))}
      {error && (
        <ErrorText fontSize={16} style={{ marginBottom: 20 }}>
          {error}
        </ErrorText>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-content'
        }}
      >
        <Button
          type="button"
          style={{ marginRight: 20 }}
          onClick={() => {
            onChange([...dates, { event_date: '' }]);
          }}
        >
          Add a date and time
        </Button>
      </div>
    </div>
  );
};

export default DateTimeRepeater;
