import moment from 'moment';
import React from 'react';
import { DateCard } from 'components/DateCard';
import Icon from 'components/Icon';
import Text from 'components/Text';
import useTheme from 'hooks/useTheme';
import { ExportRegistrations } from './ExportRegistrations';
import { useGetAllEventRegistrations } from './gql';

export const RegistrationsHeader = ({ ticketFilter, data, ticket_id }) => {
  const theme = useTheme();
  const { data: reportData } = useGetAllEventRegistrations(ticket_id);
  if (!reportData?.getAllEventRegistrations) return null;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        margin: 40,
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <DateCard item={data?.getEvent} margin={'margin: 0 10px 0 0'} />

        <Text type="heading" fontSize={32} color={theme.colors.secondary}>
          {data.getEvent.name}
        </Text>
        {'postponed' === data.getEvent.status ? (
          <img
            style={{
              width: '20%',
              height: 'auto',
              display: 'block',
              marginLeft: 20,
              transform: 'rotate(10deg)',
            }}
            src="https://d3294qt0f4hbwl.cloudfront.net/postponed.png"
            alt="postponed-stamp"
          />
        ) : null}
        {'cancelled' === data.getEvent.status ? (
          <img
            style={{
              width: '20%',
              height: 'auto',
              display: 'block',
              marginLeft: 20,
            }}
            src="https://d3294qt0f4hbwl.cloudfront.net/cancelled.png"
            alt="cancelled-stamp"
          />
        ) : null}
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {reportData.getAllEventRegistrations.results.length > 0 && (
          <ExportRegistrations
            date={
              data?.getEvent?.isMultiDay
                ? `${data?.getEvent?.fullMonth} ${
                    data?.getEvent?.listDates
                  } ${moment(data?.getEvent?.end_date).format('YYYY')} `
                : data?.getEvent?.fullDate.replace(/,/g, ' ')
            }
            transactions={reportData.getAllEventRegistrations.results}
            event={data?.getEvent}
            icon={<Icon icon="Export-Icon" size={40} />}
            total={reportData.getAllEventRegistrations.count}
            ticketFilter={ticketFilter}
          />
        )}
      </div>
    </div>
  );
};
