import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { ReactSortable } from 'react-sortablejs';
import styled from 'styled-components';
import { debounce } from 'throttle-debounce';
import { Input } from 'components/Form/Input';
import { DatePicker } from 'components/Form/DatePicker';
import {
  DraggableRow,
  IconWrapper,
  FormWrapper,
  getHighestItemOrderNumber,
  getOrderedItems
} from 'components/Form/DraggableFields';
import Icon from 'components/Icon';
import { DragIndicatorIcon } from 'components/Icon/DragIndicatorIcon';
import Spacer from 'components/Spacer';
import { Button, RemoveButton } from 'components/Button';
import { Row, Col } from 'react-grid-system';
import { ErrorText } from 'components/Form/styles';
import Text from 'components/Text';
import { Select } from 'components/Form/Select';
import useTheme from 'hooks/useTheme';
import { DESCRIPTION_CHARACTER_LIMIT } from '.';

const COLOR_CODES = [
  { label: 'Red', value: '#FF0000' },
  { label: 'Burgundy', value: '#950700' },
  { label: 'Magenta', value: '#FF0067' },
  { label: 'Black', value: '#000000' },
  { label: 'Gold', value: '#887A13' },
  { label: 'Baby Blue', value: '#00BBFF' },
  { label: 'Sky Blue', value: '#00F5FF' },
  { label: 'Blue', value: '#0034FF' },
  { label: 'Navy Blue', value: '#150067' },
  { label: 'Purple', value: '#580085' },
  { label: 'Light Purple', value: '#A743FF' },
  { label: 'School Bus Yellow', value: '#FCB92A' },
  { label: 'Bright Yellow', value: '#FDFA00' },
  { label: 'Yellow', value: '#FCE603' },
  { label: 'Brown', value: '#501D1D' },
  { label: 'Charcoal', value: '#5F534D' },
  { label: 'Rust Red', value: '#A04010' },
  { label: 'Pit Pay Orange', value: '#fa4616' },
  { label: 'Pit Pay Dark Blue', value: '#00001f' },
  { label: 'Avocado Green', value: '#90A010' },
  { label: 'Green Grass', value: '#00A526' },
  { label: 'Deere Green', value: '#367C2B' },
  { label: 'Lime Green ', value: '#AEFF00' },
  { label: 'Turquoise', value: '#00ADA7' },
  { label: 'Mint Green ', value: '#43EE9E' },
  { label: 'Pink', value: '#FF8BFE' },
  { label: 'Plum Purple', value: '#620961' },
  { label: 'Grey', value: '#A7A6A7' }
];

const TicketHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CollapseButton = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  & > div {
    margin-left: 4px;
  }
  &.collapsed > div {
    transform: rotate(-90deg);
  }
  &:focus {
    outline: none;
    text-decoration: underline;
  }
`;

const TicketCollapse = styled.div`
  &.collapsed {
    display: none;
  }
`;

const InputWrapper = styled.div`
  margin-top: 10px;
`;

const CharacterCount = styled.span`
  color: ${props => (props.overLimit ? props.theme.colors.error : 'unset')};
  margin-bottom: 6px;
`;
CharacterCount.propTypes = {
  overLimit: PropTypes.bool
};

const Ticket = ({
  handleChange,
  ticket,
  index,
  ticketType,
  handleDelete,
  collapsed
}) => {
  const [characterCount, setCharacterCount] = useState(0);

  const ticketLimit = (
    <InputWrapper>
      <Input
        id={`${ticketType}.${index}.limit`}
        name={`${ticketType}.${index}.limit`}
        label="Ticket Limit"
        placeholder="Ticket Limit"
        onChange={({ target }) =>
          handleChange({
            target: {
              name: `limit`,
              value: target.value
            }
          })
        }
        value={ticket.limit ?? ''}
      />
    </InputWrapper>
  );

  return (
    <>
      {collapsed && <Text type="label">{ticket.name || 'New ticket'}</Text>}
      <TicketCollapse className={collapsed ? 'collapsed' : null}>
        <RemoveButton
          style={{ marginBottom: 20, marginTop: 20 }}
          onClick={handleDelete}
        />
        <InputWrapper>
          <Input
            id={`${ticketType}.${index}.name`}
            name={`${ticketType}.${index}.name`}
            label="Ticket Name"
            placeholder="Ticket Name"
            onChange={({ target }) =>
              handleChange({
                target: {
                  name: `name`,
                  value: target.value
                }
              })
            }
            value={ticket.name ?? ''}
          />
        </InputWrapper>

        <InputWrapper>
          <Input
            id={`${ticketType}.${index}.price`}
            name={`${ticketType}.${index}.price`}
            label="Ticket Price"
            placeholder="Ticket Price"
            onChange={({ target }) =>
              handleChange({
                target: {
                  name: `price`,
                  value: target.value
                }
              })
            }
            value={ticket.price ?? ''}
          />
        </InputWrapper>

        {(ticketType === 'admin_tickets' ||
          ticketType === 'admin_other_tickets') &&
          ticketLimit}

        {(ticketType === 'admin_multiday_tickets' ||
          ticketType === 'admin_registrations') && (
          <Row>
            <Col>
              <InputWrapper>
                <DatePicker
                  name={`${ticketType}.${index}.start_date`}
                  type="date"
                  label="Start Date"
                  value={ticket.start_date}
                  onChange={(_, value) =>
                    handleChange({ target: { name: 'start_date', value } })
                  }
                />
              </InputWrapper>
            </Col>
            <Col>
              <InputWrapper>
                <DatePicker
                  name={`${ticketType}.${index}.end_date`}
                  type="date"
                  label="End Date"
                  value={ticket.end_date}
                  onChange={(_, value) =>
                    handleChange({ target: { name: 'end_date', value } })
                  }
                />
              </InputWrapper>
            </Col>
          </Row>
        )}

        {(ticketType === 'admin_multiday_tickets' ||
          ticketType === 'admin_registrations') &&
          ticketLimit}

        {ticketType === 'admin_registrations' && (
          <InputWrapper>
            <Input
              id={`${ticketType}.${index}.form_id`}
              name={`${ticketType}.${index}.form_id`}
              label="Form"
              placeholder="Formstack Form ID"
              onChange={({ target }) =>
                handleChange({
                  target: {
                    name: `form_id`,
                    value: target.value
                  }
                })
              }
              value={ticket.form_id}
            />
          </InputWrapper>
        )}

        <InputWrapper>
          <Select
            id={`${ticketType}.${index}.color_code`}
            name={`${ticketType}.${index}.color_code`}
            label="Color Code"
            placeholder="Select a Color"
            options={COLOR_CODES}
            onChange={({ target }) =>
              handleChange({
                target: {
                  name: `color_code`,
                  value: target.value
                }
              })
            }
            value={ticket.color_code}
          />
        </InputWrapper>

        {(ticketType === 'admin_tickets' ||
          ticketType === 'admin_other_tickets') && (
          <InputWrapper>
            <DatePicker
              name={`${ticketType}.${index}.start_date`}
              type="date"
              label={
                ticketType === 'admin_other_tickets' ? 'Start Date' : 'Date'
              }
              value={ticket.start_date}
              onChange={(_, value) =>
                handleChange({ target: { name: 'start_date', value } })
              }
            />
          </InputWrapper>
        )}

        {ticketType === 'admin_other_tickets' && (
          <InputWrapper>
            <DatePicker
              name={`${ticketType}.${index}.end_date`}
              type="date"
              label="End Date"
              value={ticket.end_date}
              onChange={(_, value) =>
                handleChange({ target: { name: 'end_date', value } })
              }
            />
          </InputWrapper>
        )}

        {ticketType !== 'admin_registrations' && (
          <InputWrapper>
            <Input
              as="textarea"
              labelRight={
                <CharacterCount
                  overLimit={characterCount > DESCRIPTION_CHARACTER_LIMIT}
                >
                  {characterCount} characters
                </CharacterCount>
              }
              rows={2}
              inputStyle={{ minHeight: 'unset' }}
              id={`${ticketType}.${index}.description`}
              name={`${ticketType}.${index}.description`}
              label="Description"
              placeholder="Ticket Description"
              onChange={({ target }) => {
                typeof target.value === 'string' &&
                  debounce(300, setCharacterCount(target.value.length));
                handleChange({
                  target: {
                    name: `description`,
                    value: target.value
                  }
                });
              }}
              value={ticket.description ?? ''}
            />
          </InputWrapper>
        )}
      </TicketCollapse>
      {!collapsed && <Spacer size={18} />}
    </>
  );
};

export const EditEventTickets = ({
  tickets,
  ticketType,
  onChange,
  error,
  addButtonText
}) => {
  const theme = useTheme();
  const [isCollapsed, setIsCollapsed] = useState(false);
  // Remove 'admin' and replace underscores with spaces
  const ticketTypeName = ticketType.split('_').slice(1).join(' ');

  const ticketsWithOrderNumbers = getOrderedItems(tickets);

  return (
    <div style={{ padding: 40, paddingBottom: 0 }}>
      <TicketHeader>
        <Text
          type="label"
          color={theme.colors.black}
          inlineStyle={{ textTransform: 'capitalize' }}
        >
          {ticketTypeName}
        </Text>
        {ticketsWithOrderNumbers.length > 0 && (
          <CollapseButton
            type="button"
            onClick={() => setIsCollapsed(isCollapsed => !isCollapsed)}
            className={isCollapsed ? 'collapsed' : null}
          >
            Sort
            <Icon size={12} icon="down-arrow" />
          </CollapseButton>
        )}
      </TicketHeader>
      {ticketsWithOrderNumbers?.length > 0 && (
        <ReactSortable list={tickets} setList={onChange}>
          {ticketsWithOrderNumbers.map((ticket, index) => (
            <DraggableRow key={ticket.order}>
              <IconWrapper>
                <DragIndicatorIcon />
              </IconWrapper>
              <FormWrapper>
                <Ticket
                  key={ticket.order}
                  ticket={ticket}
                  ticketType={ticketType}
                  index={index}
                  handleDelete={() => {
                    const newTickets = tickets.filter(
                      (_, currentIndex) => currentIndex !== index
                    );
                    onChange(newTickets);
                  }}
                  handleChange={({ target }) => {
                    const newTickets = tickets.map((ticket, ticketIndex) =>
                      ticketIndex === index
                        ? { ...ticket, [target.name]: target.value }
                        : ticket
                    );
                    onChange(newTickets);
                  }}
                  collapsed={isCollapsed}
                />
              </FormWrapper>
            </DraggableRow>
          ))}
        </ReactSortable>
      )}
      {error && (
        <ErrorText fontSize={16} style={{ marginBottom: 20 }}>
          {error}
        </ErrorText>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-content'
        }}
      >
        <Button
          type="button"
          onClick={() =>
            onChange([
              ...tickets,
              {
                isMultiDay:
                  ticketType === 'admin_multiday_tickets' ||
                  ticketType === 'admin_registrations',
                order: getHighestItemOrderNumber(ticketsWithOrderNumbers) + 1
              }
            ])
          }
        >
          {addButtonText}
        </Button>
      </div>
    </div>
  );
};

const ticketProp = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  start_date: PropTypes.string,
  end_date: PropTypes.string,
  color_code: PropTypes.string,
  description: PropTypes.string,
  limit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
});

const ticketTypeProp = PropTypes.oneOf([
  'admin_tickets',
  'admin_multiday_tickets',
  'admin_other_tickets',
  'admin_registrations'
]);

Ticket.propTypes = {
  ticket: ticketProp.isRequired,
  ticketType: ticketTypeProp.isRequired,
  index: PropTypes.number.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired
};

EditEventTickets.propTypes = {
  tickets: PropTypes.arrayOf(ticketProp).isRequired,
  ticketType: ticketTypeProp.isRequired,
  onChange: PropTypes.func.isRequired,
  addButtonText: PropTypes.string.isRequired,
  error: PropTypes.string
};
