import React from 'react';

import TrackHeader from './TrackHeader';
import SeriesHeader from './series-header';

export const EventHeader = props => {
  const { type, id, margin } = props;

  return 'track' === type ? (
    <TrackHeader id={id} margin={margin} />
  ) : (
    <SeriesHeader id={id} margin={margin} />
  );
};
